import styled from '@emotion/styled';
import theme from '../../../commons/theme';

export const ApplyWrapper = styled.section`
    width: 100%;
    height: 500px;
    background-color: #fcfcfc;
`;

export const ApplyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: auto;
    /* border: 1px solid gold; */
`;

export const ApplyTitle = styled.div`
    width: 390px;
    height: 60%;
    padding-top: 14px;
    padding-bottom: 54px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: ${theme.title};
    /* border: 1px solid red; */

    h1 {
        color: ${theme.grey_900};
        font-size: 3.4rem;
    }

    span {
        display: inline-block;
        margin: 8px 0px 94px 0px;
        color: ${theme.primaryGreen};
        font-family: ${theme.subTitle};
        font-size: 1.8rem;
    }

    button {
        display: block;
        width: 240px;
        height: 54px;
        border: none;
        border-radius: 100px;
        box-shadow: none;
        background-color: ${theme.primaryGreen};
        color: white;
        font-size: 1.8rem;

        :hover {
            box-shadow: none;
            background-color: ${theme.hoverGreen};
            color: white;
        }
    }
`;

export const ApplyForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 700px;
    height: 60%;
    /* border: 1px solid blue; */
`;

export const InputWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30%;
    font-family: ${theme.subTitle};
    font-size: 1.8rem;
    /* border: 1px solid pink; */

    label {
        width: 90px;
        height: 42px;
        line-height: 35px;
    }

    span {
        color: ${theme.primaryGreen};
    }
`;

export const InputBox = styled.input`
    width: ${({ width }) => width};
    height: 38px;
    padding: 10px 26px 10px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: ${theme.grey_600};
    font-family: ${theme.text};
    font-size: 1.8rem;
    appearance: none;

    :hover {
        border: 1px solid ${theme.primaryGreen};
        cursor: pointer;
    }

    :focus {
        outline: none;
        border: 1px solid ${theme.primaryGreen};
    }
`;

export const ErrorInfo = styled.span`
    position: absolute;
    bottom: 0px;
    left: ${({ left }) => left};
    color: ${theme.warning} !important;
    font-size: 1.4rem;
`;
