import React, { useContext } from 'react';
import { ApplyContext } from '../../../contexts/ApplyContext';
import * as S from './IntroPrice.style';
import { motion } from 'framer-motion';
import MemberShip from './MemberShip';
import Personal from './Personal';
import Process from './Process';
import { container, title, content } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function IntroPrice() {
    const { setService } = useContext(ApplyContext);
    const { i18n } = useTranslation();
    return (
        <S.PriceWrapper id="intro-price">
            <S.PriceContent>
                <motion.div
                    variants={container}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true }}
                    style={{ width: '1090px' }}
                >
                    <motion.div variants={title} transition={{ duration: 0.7 }}>
                        <S.Title>{t('introPrice.title')}</S.Title>
                        {i18n.language === 'ko' && <S.Title>{t('introPrice.title2')}</S.Title>}
                    </motion.div>
                    <S.Text variants={content} transition={{ duration: 0.7 }}>
                        {t('introPrice.text')}
                    </S.Text>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.7, delay: 0.5 }}
                    viewport={{ once: true }}
                >
                    <MemberShip setService={setService} />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.7, delay: 0.5 }}
                    viewport={{ once: true }}
                >
                    <Personal setService={setService} />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.7 }}
                    viewport={{ once: true }}
                >
                    <Process />
                </motion.div>
            </S.PriceContent>
        </S.PriceWrapper>
    );
}

export default IntroPrice;
