import Title from '../Title';
import styled from '@emotion/styled';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import listIcon from '../../../assets/image/price/list_icon.png';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import theme from '../../../commons/theme';
import { personalMenu } from '../../../commons/data';
import { motion } from 'framer-motion';
import { container, title } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';
function Personal({ setService }) {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();

    return (
        <PersonalWrapper variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
            <motion.div variants={title} transition={{ duration: 0.7 }}>
                {i18n.language === 'ko' ? (
                    <Title title={t('personal.mtitle')} text={t('personal.mtitle2')} />
                ) : (
                    <>
                        <Title title={t('personal.mtitle')} />
                        <Title text={t('personal.mtitle2')} />
                    </>
                )}
            </motion.div>
            <Swiper
                spaceBetween={30}
                style={{ height: isEng ? '690px' : '575px' }}
                pagination={{ clickable: true }}
                speed={800}
                modules={[Pagination]}
            >
                {personalMenu.map(el => (
                    <SwiperSlide key={el.id}>
                        <PersonalContentMenu
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.7 }}
                            viewport={{ once: true }}
                        >
                            <motion.h1
                                initial={{ opacity: 0, y: 5 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.7 }}
                                viewport={{ once: true }}
                            >
                                {t(el.title)}
                            </motion.h1>
                            <motion.div
                                initial={{ opacity: 0, y: 5 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.7 }}
                                viewport={{ once: true }}
                            >
                                <h3>{t(el.sub)}</h3>
                                <h6>{t(el.dec)}</h6>
                            </motion.div>
                            <motion.h5
                                initial={{ opacity: 0, y: 5 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.7, delay: 0.3 }}
                                viewport={{ once: true }}
                            >
                                {t('personal.subTitle')}
                            </motion.h5>
                            <motion.ul
                                initial={{ opacity: 0, y: 5 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.7, delay: 0.3 }}
                                viewport={{ once: true }}
                            >
                                {el.text.map(info => (
                                    <li key={info}>
                                        <img src={listIcon} />
                                        <pre>{t(info)}</pre>
                                    </li>
                                ))}
                            </motion.ul>
                            <motion.div
                                initial={{ opacity: 0, y: 3 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.7, delay: 0.6 }}
                                viewport={{ once: true }}
                            >
                                <Link to="apply" smooth={true} duration={1500}>
                                    <Button
                                        style={{ marginTop: isEng ? '155px' : '50px' }}
                                        variant="contained"
                                        sx={{ mt: '50px' }}
                                        onClick={() => setService(el.service)}
                                    >
                                        {t('personal.button')}
                                    </Button>
                                </Link>
                            </motion.div>
                        </PersonalContentMenu>
                    </SwiperSlide>
                ))}
            </Swiper>
        </PersonalWrapper>
    );
}

export default Personal;

const PersonalWrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;

    span {
        opacity: 1;
    }

    .swiper {
        width: 309px;
        height: 575px;
        margin-top: 20px;
        padding: 5px;
        border: none;
    }

    .swiper-slide {
        height: 90%;
        border-radius: 4px;
        border: 2px solid white;
        box-shadow: 0px 2px 10px rgba(66, 66, 66, 0.1);
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0px 10px !important;
        background-color: ${theme.grey_300} !important;
    }

    .swiper-pagination-bullet-active {
        background-color: ${theme.secondary} !important;
    }
`;

const PersonalContentMenu = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 35px 24px;
    /* border-radius: 4px;
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px; */

    div {
        width: 100%;
        height: 55px;
        margin-bottom: 30px;
    }

    h1 {
        width: 100%;
        color: ${theme.primaryGreen};
        font-family: ${theme.title};
        font-size: 2.4rem;
    }

    h3 {
        width: 100%;
        color: ${theme.grey_800};
        font-family: ${theme.subTitle};
        font-size: 1.8rem;
        white-space: nowrap;
    }

    h5 {
        width: 100%;
        margin-bottom: 10px;
        color: ${theme.grey_800};
        font-family: ${theme.subTitle};
        font-size: 1.8rem;
    }

    h6 {
        width: 100%;
        color: ${theme.grey_400};
        font-family: ${theme.desc};
        font-size: 1.2rem;
    }

    ul {
        width: 100%;
        height: 193px;
        list-style: none;

        li {
            position: relative;
            display: flex;
            margin-bottom: 10px;
            padding-left: 15px;

            img {
                position: absolute;
                top: 8px;
                left: 0;
                width: 8px;
                height: 8px;
            }

            pre {
                color: ${theme.grey_800};
                font-family: ${theme.desc};
                font-size: 1.6rem;
            }
        }
    }
`;
