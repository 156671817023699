import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import theme from '../../../commons/theme';
import i18n from 'i18next';

export const ServiceWrapper = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 255px;
`;

export const ServiceContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    /* border: 1px solid gold; */
`;

export const Title = styled.h1`
    color: ${theme.grey_900};
    font-family: ${theme.title};
    font-size: 4.8rem;
`;

export const Text = styled.pre`
    margin-top: 24px;
    color: ${({ bold }) => (bold ? theme.primaryGreen : theme.grey_800)};
    font-family: ${({ bold }) => (bold ? theme.subTitle : theme.text)};
    font-size: 2.2rem;
`;

export const SwiperWrapper = styled(motion.div)`
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 1090px;
    height: 553px;
    margin-top: 124px;
    /* border: 1px solid blue; */

    .swiper {
        width: 50%;
        height: 100%;
        border-radius: 4px;
        box-shadow: 4px 6px 15px rgba(66, 66, 66, 0.15);
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        span {
            opacity: 1;
            background-color: white;
        }
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0px 15px !important;
    }

    .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
    }

    button {
        position: absolute;
        bottom: 53px;
        left: 0px;
        width: ${i18n.language === 'en' ? '250px' : '224px'};
        height: 48px;
        border: 1px solid ${theme.primaryGreen};
        border-radius: 100px;
        color: ${theme.grey_600} !important;
        font-family: ${theme.subTitle};
        font-size: 1.6rem;

        span {
            color: ${theme.primaryGreen} !important;
        }
        :hover {
            border: 1px solid ${theme.primaryGreen};
            color: ${theme.primaryGreen} !important;
            background-color: white;
        }
    }
`;

export const SlideInfo = styled.div`
    width: 50%;
    height: 100%;
    /* border: 1px solid red; */

    img {
        width: 240px;
        height: 26px;
        object-fit: contain;
        object-position: left;
    }
`;

export const InfoTitle = styled(motion.h1)`
    margin-bottom: 52px;
    color: ${theme.primaryGreen};
    font-family: ${theme.title};
    font-size: 4.8rem;
`;

export const InfoText = styled(motion.pre)`
    height: 235px;
    margin-top: 18px;
    color: ${theme.grey_900};
    font-family: ${theme.text};
    font-size: 1.8rem;
    line-height: 32px;
`;
