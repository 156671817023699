import * as React from 'react';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import theme from '../../commons/theme';

const SuccessInfo = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transform: translate(-50%, -50%);
    width: 453px;
    height: 244px;
    border-radius: 4px;
    background-color: white;
    text-align: center;

    h1 {
        color: ${theme.primaryGreen};
        font-family: ${theme.subTitle};
        font-size: 2.2rem;
    }

    pre {
        color: ${theme.grey_800};
        font-family: ${theme.text};
        font-size: 1.8rem;
    }

    @media all and (max-width: 768px) {
        width: 327px;

        h1 {
            font-size: 1.8rem;
        }

        pre {
            font-size: 1.6rem;
        }
    }
`;

const Button = styled.button`
    width: 164px;
    height: 48px;
    border: none;
    border-radius: 100px;
    border: 1px solid ${theme.primaryGreen};
    background-color: white;
    color: ${theme.primaryGreen};
    font-family: ${theme.subTitle};
    font-size: 1.8rem;

    :hover {
        cursor: pointer;
    }

    @media all and (max-width: 768px) {
        width: 248px;
        font-size: 1.6rem;
    }
`;

function SuccessModal({ open, handleClose }) {
    return (
        <Modal open={open} onClose={handleClose}>
            <SuccessInfo>
                <h1>문의 완료</h1>
                <pre>{`팜스플랜 상담 신청이 완료되었습니다.\n담당자가 빠르게 연락드리겠습니다.`}</pre>
                <Button onClick={handleClose}>확인</Button>
            </SuccessInfo>
        </Modal>
    );
}

export default SuccessModal;
