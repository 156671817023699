import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import AppSlide from './AppSlide';
import app1 from '../../../assets/image/app/app_1.png';
import app2 from '../../../assets/image/app/app_2.png';
import app3 from '../../../assets/image/app/app_3.png';
import theme from '../../../commons/theme';
import LINKS from '../../../commons/links';
import googleIcon from '../../../assets/image/app/google_play.png';
import { container, title, content } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';
function IntroApp() {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();

    return (
        <AppWrapper>
            <AppInnerWrapper variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                <Title variants={title} transition={{ duration: 0.7 }}>
                    {/* <h1>
                        <span>{t('intro.mtitle')}</span>
                    </h1>
                    <h1>{t('intro.mtitle2')}</h1>
                    <h1>{t('intro.mtitle3')}</h1> */}
                    {i18n.language === 'ko' ? (
                        <>
                            <h1>
                                <span>{t('intro.mtitle')}</span>
                            </h1>
                            <h1>{t('intro.mtitle2')}</h1>
                            <h1>{t('intro.mtitle3')}</h1>
                        </>
                    ) : (
                        <>
                            <h1>
                                {t('intro.mtitle2')}
                                {t('intro.mtitle')}
                                <span>{t('intro.mtitle3')}</span>
                            </h1>
                        </>
                    )}
                </Title>
                <motion.div variants={content} transition={{ duration: 0.7 }}>
                    <pre>{t('intro.mtext1')}</pre>
                    <AppSlide appImage={[app1, app2, app3]} />
                </motion.div>
                <motion.div variants={content} transition={{ duration: 0.7 }}>
                    <pre>{t('intro.mtext2')}</pre>
                </motion.div>
                <motion.div variants={content} transition={{ duration: 0.7 }}>
                    <SubTitle className={isEng ? '' : 'white-space-nowrap'}>{t('intro.mtext3')}</SubTitle>
                    <GooglePlayButton
                        onClick={() => {
                            window.open(LINKS.farmsplanApp);
                        }}
                    >
                        <img src={googleIcon} alt="icon" />
                    </GooglePlayButton>
                </motion.div>
            </AppInnerWrapper>
        </AppWrapper>
    );
}

export default IntroApp;

const AppWrapper = styled.section`
    display: flex;
    justify-content: center;
    padding-top: 155px;
    padding-bottom: 35px;
    background-color: #fcfcfc;

    pre {
        width: 100%;
        margin-top: 24px;
        color: ${theme.grey_800};
        font-family: ${theme.desc};
        font-size: 1.6rem;
    }
`;

const AppInnerWrapper = styled(motion.div)`
    width: 295px;
`;

const Title = styled(motion.div)`
    color: ${theme.grey_900};

    h1 {
        line-height: 50px;
        font-family: ${theme.title};
        font-size: 3.8rem;
        white-space: nowrap;
    }

    span {
        color: ${theme.primaryGreen};
    }
`;

const SubTitle = styled.span`
    display: block;
    margin-top: 8px;
    margin-bottom: 48px;
    font-family: ${theme.subTitle};
    font-size: 1.8rem;
    color: ${theme.primaryGreen};
    white-space: nowrap;
`;

const GooglePlayButton = styled.button`
    width: 165px;
    height: 48px;
    border: 0.5px solid ${theme.grey_400};
    border-radius: 104px;

    :hover {
        cursor: pointer;
    }

    img {
        width: 100%;
        height: 100%;
    }
`;
