import styled from '@emotion/styled';
import React from 'react';
import theme from '../../../commons/theme';
import farmsplan from '../../../assets/image/brand/m_farmsplan.png';
import meat from '../../../assets/image/brand/m_meat.png';
import artlist from '../../../assets/image/brand/m_artlist.jpg';
import gully from '../../../assets/image/brand/m_gully.jpg';
import LINKS from '../../../commons/links';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { container, title, content } from '../../../commons/variants';
import { t } from 'i18next';

const SLIDEIMAGE = [farmsplan, meat, gully, artlist];

function IntroBrand() {
    return (
        <BrandWrapper>
            <TitleWrapper variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                <motion.h1 variants={title} transition={{ duration: 0.7 }}>
                    {t('introBrand.mtitle')}
                </motion.h1>
                <motion.pre variants={content} transition={{ duration: 0.7 }}>
                    {t('introBrand.mtext')}
                </motion.pre>
            </TitleWrapper>
            <BrandSlide
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: 0.7 }}
                viewport={{ once: true }}
            >
                <BrandSwiper
                    pagination={{ clickable: true }}
                    speed={800}
                    autoplay={{ disableOnInteraction: false, delay: 2500 }}
                    modules={[Autoplay, Pagination]}
                    loop
                >
                    {SLIDEIMAGE.map(el => (
                        <SwiperSlide key={el}>
                            <SlideImage src={el} />
                        </SwiperSlide>
                    ))}
                </BrandSwiper>
                <Button
                    variant="outlined"
                    endIcon={<ArrowForwardIcon />}
                    onClick={() => {
                        window.open(LINKS.gullyjumper);
                    }}
                >
                    {t('introBrand.mlink1')}
                </Button>
                <Button
                    variant="outlined"
                    endIcon={<ArrowForwardIcon />}
                    onClick={() => {
                        window.open(LINKS.farmsplanMarket);
                    }}
                >
                    {t('introBrand.mlink2')}
                </Button>
            </BrandSlide>
        </BrandWrapper>
    );
}

export default IntroBrand;

const BrandWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 176px;
    padding-bottom: 176px;
`;

const TitleWrapper = styled(motion.div)`
    width: 295px;
    /* border: 1px solid gold; */

    h1 {
        line-height: 50px;
        color: ${theme.grey_900};
        font-family: ${theme.title};
        font-size: 3.8rem;
        white-space: nowrap;
    }

    pre {
        margin-top: 24px;
        margin-bottom: 56px;
        color: ${theme.grey_800};
        font-family: ${theme.desc};
        font-size: 1.6rem;
    }
`;

const BrandSlide = styled(motion.div)`
    width: 295px;
    /* border: 1px solid red; */

    button {
        display: flex;
        justify-content: space-between;
        width: 295px;
        height: 48px;
        margin-bottom: 12px;
        padding: 0px 30px;
        border: 1px solid ${theme.primaryGreen};
        border-radius: 100px;
        color: ${theme.grey_600};
        font-family: ${theme.subTitle};
        font-size: 1.6rem;

        span {
            color: ${theme.primaryGreen};
        }

        :hover {
            border: 1px solid ${theme.primaryGreen};
            color: ${theme.primaryGreen};
            background-color: white;
        }
    }
`;

const BrandSwiper = styled(Swiper)`
    height: 265px;
    margin-bottom: 20px;
    border-radius: 5px;

    span {
        opacity: 1;
        background-color: ${theme.grey_300};
    }

    .swiper-slide {
        width: 295px !important;
        height: 217px;
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0px 10px !important;
    }

    .swiper-pagination-bullet-active {
        background-color: ${theme.grey_400} !important;
    }
`;

const SlideImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
`;
