import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

export const Video = styled.video`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
`;

function SlideVideo({ isActive, src }) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (isActive) {
            videoRef.current.play();
            videoRef.current.currentTime = 0;
        }else {
            videoRef.current.pause();
        }
        
    }, [isActive]);

    return <Video src={src}  muted playsInline ref={videoRef} />;
}

export default SlideVideo;
