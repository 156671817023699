import React from 'react';
import styled from '@emotion/styled';
import Title from '../Title';
import theme from '../../../commons/theme';
import { personalMenu } from '../../../commons/data';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import listIcon from '../../../assets/image/price/list_icon.png';
import { t } from 'i18next';
import useCheckEng from '../../../util/useCheckEng';

const PersonalWrapper = styled.div`
    width: 1090px;
    /* border: 1px solid red; */
    margin-top: 72px;
`;

const PersonalContent = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const PersonalContentMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 32px 0px 32px;
    width: 23%;
    height: 534px;
    border-radius: 4px;
    border: 2px solid white;
    box-shadow: 2px 4px 15px rgba(66, 66, 66, 0.1);
    transition: all 0.5s;

    div {
        width: 100%;
        height: 55px;
        margin-bottom: 30px;
    }

    h1 {
        width: 100%;
        color: ${theme.primaryGreen};
        font-family: ${theme.title};
        font-size: 3.2rem;
    }

    h3 {
        width: 100%;
        color: ${theme.grey_800};
        font-family: ${theme.subTitle};
        font-size: 2.2rem;
        white-space: nowrap;
    }

    h5 {
        width: 100%;
        margin-bottom: 10px;
        color: ${theme.grey_800};
        font-family: ${theme.subTitle};
        font-size: 1.8rem;
    }

    h6 {
        width: 100%;
        color: ${theme.grey_400};
        font-family: ${theme.desc};
        font-size: 1.4rem;
    }

    ul {
        width: 100%;
        height: 193px;
        list-style: none;

        li {
            position: relative;
            display: flex;
            margin-bottom: 10px;
            padding-left: 15px;

            img {
                position: absolute;
                top: 8px;
                left: 0;
                width: 8px;
                height: 8px;
            }

            pre {
                color: ${theme.grey_800};
                font-family: ${theme.text};
                font-size: 1.8rem;
            }
        }
    }

    :hover {
        border: 2px solid ${theme.primaryGreen};
        transform: translateY(-2%);
        transition: transform 0.5s;
    }
`;

function Personal({ setService }) {
    const isEng = useCheckEng();

    return (
        <PersonalWrapper>
            <Title title={t('personal.title')} text={t('personal.title2')} />
            <PersonalContent>
                {personalMenu.map(el => (
                    <PersonalContentMenu
                        key={el.id}
                        style={{
                            height: isEng ? '750px' : '534px',
                            padding: isEng ? '20px 20px 0px 20px' : '32px 32px 0px 32px',
                            width: isEng ? '23.9%' : '23%',
                        }}
                    >
                        <h1>{t(el.title)}</h1>
                        <div style={{ marginBottom: isEng ? '70px' : '44px' }}>
                            <h3>{t(el.sub)}</h3>
                            <h6>{t(el.dec)}</h6>
                        </div>
                        <h5>{t('personal.subTitle')}</h5>
                        <ul>
                            {el.text.map(info => (
                                <li key={info}>
                                    <img src={listIcon} />
                                    <pre>{t(info)}</pre>
                                </li>
                            ))}
                        </ul>
                        <Link to="apply" smooth={true} duration={1500}>
                            <Button
                                variant="contained"
                                sx={{ mt: '50px' }}
                                onClick={() => setService(el.service)}
                                style={{ marginTop: isEng ? '250px' : '50px' }}
                            >
                                {t('personal.button')}
                            </Button>
                        </Link>
                    </PersonalContentMenu>
                ))}
            </PersonalContent>
        </PersonalWrapper>
    );
}

export default Personal;
