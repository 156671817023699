import { t } from 'i18next';
import React, { useState } from 'react';
import IntroServiceUI from './IntroService.presenter';
import { slideContent } from '../../../commons/data';

function IntroService() {
    const [activeIndex, setActiveIndex] = useState(0);

    return <IntroServiceUI activeIndex={activeIndex} setActiveIndex={setActiveIndex} slideContent={slideContent} />;
}

export default IntroService;
