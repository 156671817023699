import React, { useState } from 'react';
import LINKS from '../../../commons/links';
import * as S from './Footer.style';
import AidkrLogo from '../../../assets/image/aidkr_log.png';
import AidkrLogoEng from '../../../assets/image/aidkr_log_eng.png';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { i18n } = useTranslation();
    const [isView, setIsView] = useState(false);
    const handleMenu = event => {
        setIsView(false);
        event.target.id && window.open(`${LINKS[event.target.id]}`);
    };
    return (
        <S.FooterWrapper>
            <S.FooterMain>
                <S.CompanyInfo>
                    <S.CompanyInfoTitle>{t('footer.companyTitle')}</S.CompanyInfoTitle>
                    <S.CompanyInfoTitle bold={true} onClick={() => window.open('https://www.aidkr.com/privacy-policy')}>
                        {t('footer.companyTitle2')}
                    </S.CompanyInfoTitle>
                    <S.CompanyInfoTitle bold={true} onClick={() => window.open('https://www.aidkr.com/terms')}>
                        {t('footer.companyTitle3')}
                    </S.CompanyInfoTitle>
                    <S.CompanyInfoText>{t('footer.companyText')}</S.CompanyInfoText>
                    <S.CompanyInfoText mb="6px">{t('footer.companyText2')}</S.CompanyInfoText>
                    <S.CompanyInfoText mb="30px">{t('footer.companyText3')}</S.CompanyInfoText>
                </S.CompanyInfo>
                <S.CompanyAdress>
                    <div>
                        <h1>{t('footer.adress')}</h1>
                        <span>{t('footer.adress_text')}</span>
                    </div>
                    <div>
                        <h1>{t('footer.adress2')}</h1>
                        <span>{t('footer.adress_text2')}</span>
                    </div>
                </S.CompanyAdress>
                <S.CompanyLink>
                    {i18n.language === 'ko' ? (
                        <S.LogoImage src={AidkrLogo} alt="" />
                    ) : (
                        <S.LogoImage src={AidkrLogoEng} alt="" />
                    )}

                    <div style={{ position: 'relative' }}>
                        <Button onClick={() => setIsView(pre => !pre)} endIcon={<ArrowDropUpIcon />}>
                            {t('footer.button')}
                        </Button>
                        {isView && (
                            <S.Meun onClick={handleMenu}>
                                <li id="aidkrHomepage">{t('footer.button2')}</li>
                                <li id="aidkrBlog">{t('footer.button3')}</li>
                                <li id="farmsplanApp">{t('footer.button4')}</li>
                                <li id="farmsplanMarket">{t('footer.button5')}</li>
                                <li id="gullyjumper">{t('footer.button6')}</li>
                                <li id="farmsplanDesign">{t('footer.button7')}</li>
                            </S.Meun>
                        )}
                    </div>
                </S.CompanyLink>
            </S.FooterMain>
        </S.FooterWrapper>
    );
}

export default Footer;
