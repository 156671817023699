import React from 'react';
import styled from '@emotion/styled';
import Title from '../Title';
import theme from '../../../commons/theme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { processMenu } from '../../../commons/data';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';

const ProcessWrapper = styled.div`
    width: 1090px;
    /* border: 1px solid red; */
    margin-top: 72px;
`;

const ProcessContent = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 24px;
`;

const ProcessStep = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.grey_800};
    font-family: ${theme.text};

    img {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
        object-fit: cover;
    }

    h5 {
        font-size: 2.2rem;
    }

    span {
        font-size: 1.8rem;
    }
`;

const ProcessArrowIcon = styled.div`
    display: flex;
    align-items: center;
`;

function Process() {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();
    return (
        <ProcessWrapper style={{ marginTop: isEng ? '0px' : '72px' }}>
            {i18n.language === 'ko' && (
                <>
                    <Title title={`도입 절차`} />
                    <ProcessContent>
                        {processMenu.map((el, idx) => (
                            <React.Fragment key={el.id}>
                                <ProcessStep>
                                    <img src={el.icon} />
                                    {el.title.map(text => (
                                        <h5 key={text}>{text}</h5>
                                    ))}
                                    <span>{el.sub}</span>
                                </ProcessStep>
                                {idx !== 3 && (
                                    <ProcessArrowIcon>
                                        <ArrowForwardIosIcon fontSize="large" sx={{ color: theme.grey_300 }} />
                                    </ProcessArrowIcon>
                                )}
                            </React.Fragment>
                        ))}
                    </ProcessContent>
                </>
            )}
        </ProcessWrapper>
    );
}

export default Process;
