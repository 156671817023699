import React from 'react';
import * as S from './IntroService.style';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { motion } from 'framer-motion';
import brochure from '../../../assets/pdf/farmsplan_brochure.pdf';
import brochureEng from '../../../assets/pdf/farmsplan_Eng.pdf';
import SlideVideo from '../SlideVideo';
import { container, title, content } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';

function IntroServiceUI({ slideContent, activeIndex, setActiveIndex }) {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();
    return (
        <S.ServiceWrapper>
            <S.ServiceContent>
                <motion.div
                    variants={container}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true }}
                    style={{ width: '1090px' }}
                >
                    <motion.div variants={title} transition={{ duration: 0.7 }}>
                        <S.Title>{t('introService.title')}</S.Title>
                        <S.Title>{t('introService.title2')}</S.Title>
                    </motion.div>
                    <motion.div variants={content} transition={{ duration: 0.7 }}>
                        <S.Text>{t('introService.text')}</S.Text>
                    </motion.div>
                </motion.div>
                <S.SwiperWrapper
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.7 }}
                    viewport={{ once: true }}
                >
                    {slideContent.map(
                        (el, idx) =>
                            activeIndex === idx + 1 && (
                                <S.SlideInfo key={el.id}>
                                    <S.InfoTitle
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        {t(el.title)}
                                    </S.InfoTitle>
                                    <motion.img
                                        src={el.logo}
                                        initial={{ opacity: 0, y: '50%' }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 1 }}
                                    />
                                    <S.InfoText
                                        initial={{ opacity: 0, y: '10%' }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 1 }}
                                    >
                                        {t(el.text)}
                                    </S.InfoText>
                                </S.SlideInfo>
                            )
                    )}
                    <Swiper
                        effect="fade"
                        pagination={{ clickable: true }}
                        autoplay={{
                            disableOnInteraction: false,
                            delay: 25000,
                        }}
                        modules={[Autoplay, Pagination, EffectFade]}
                        onSlideChange={slide => {
                            slide.activeIndex === 5 ? setActiveIndex(1) : setActiveIndex(slide.activeIndex);
                        }}
                        loop
                    >
                        {slideContent.map(el => (
                            <SwiperSlide key={el.id} data-swiper-autoplay={el.delay}>
                                {({ isActive }) => <SlideVideo isActive={isActive} src={el.video} />}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >
                        {i18n.language === 'ko' ? (
                            <Button
                                style={{
                                    width: isEng ? '260px' : '224px',
                                }}
                                onClick={() => window.open(brochure, '_blank')}
                                variant="outlined"
                                endIcon={<FileDownloadIcon />}
                            >
                                {t('data.button')}
                            </Button>
                        ) : (
                            <Button
                                style={{
                                    width: isEng ? '260px' : '224px',
                                }}
                                onClick={() => window.open(brochureEng, '_blank')}
                                variant="outlined"
                                endIcon={<FileDownloadIcon />}
                            >
                                {t('data.button')}
                            </Button>
                        )}
                    </motion.div>
                </S.SwiperWrapper>
            </S.ServiceContent>
        </S.ServiceWrapper>
    );
}

export default IntroServiceUI;
