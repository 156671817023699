import { motion } from 'framer-motion';
import React from 'react';
import * as S from './IntroOneHealth.style';
import NumberAnimation from '../NumberAnimation';
import { container, content } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';

function IntroOneHealthUI({ numbers, setNumbers }) {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();
    return (
        <S.OneHealthWrapper isEng={isEng}>
            <motion.div
                variants={container}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true }}
                style={{ position: 'relative', zIndex: '99' }}
            >
                <motion.div variants={content} transition={{ duration: 1, delay: 1 }}>
                    {i18n.language === 'ko' ? (
                        <>
                            <S.Title>
                                {t('introOne.title')}

                                <span>{t('introOne.title2')}</span>
                            </S.Title>
                        </>
                    ) : (
                        <>
                            <S.Title style={{ marginTop: '89px' }}>
                                <span>{t('introOne.title2')}</span>
                                {t('introOne.title')}
                            </S.Title>
                        </>
                    )}
                    <S.Text>{t('introOne.text')}</S.Text>
                </motion.div>

                <S.Output
                    variants={content}
                    transition={{ duration: 1, delay: 1.5 }}
                    viewport={{ once: true }}
                    onViewportEnter={() => setNumbers([67, 65, 30, 30])}
                >
                    <S.FigureWrapper>
                        <S.Figure>
                            <NumberAnimation number={numbers[0]} />
                            <div>
                                <S.ReverseTriangle />
                                <h3>%</h3>
                            </div>
                        </S.Figure>
                        <span className="en_span">{t('introOne.sub_title')}</span>
                    </S.FigureWrapper>
                    <S.FigureWrapper>
                        <S.Figure>
                            <NumberAnimation number={numbers[1]} />
                            <div>
                                <S.ReverseTriangle />
                                <h3>%</h3>
                            </div>
                        </S.Figure>
                        <span className="en_span">{t('introOne.sub_title2')}</span>
                    </S.FigureWrapper>
                    <S.FigureWrapper>
                        <S.Figure>
                            <NumberAnimation number={numbers[2]} />
                            <div>
                                <S.Triangle />
                                <h3>%</h3>
                            </div>
                        </S.Figure>
                        <span className="en_span">{t('introOne.sub_title3')}</span>
                    </S.FigureWrapper>
                    <S.FigureWrapper>
                        <S.Figure>
                            <NumberAnimation number={numbers[3]} />
                            <div>
                                <S.Triangle />
                                <h3>%</h3>
                            </div>
                        </S.Figure>
                        <span className="en_span">{t('introOne.sub_title4')}</span>
                        {i18n.language === 'en' && <span className="en_text">{t('introOne.sub_title4_1')}</span>}
                    </S.FigureWrapper>
                </S.Output>
            </motion.div>

            <S.Background
                initial={{ opacity: 0, x: '-100%' }}
                whileInView={{ opacity: 0.3, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
            />
        </S.OneHealthWrapper>
    );
}

export default IntroOneHealthUI;
