import styled from '@emotion/styled';
import theme from '../../../commons/theme';
import { Swiper } from 'swiper/react';
import { motion } from 'framer-motion';

export const BrandWrapper = styled.section`
    display: flex;
    justify-content: center;
    max-width: 1440px;
    width: 100%;
    height: 553px;
    margin: auto;
    margin-bottom: 240px;
    /* border: 1px solid gold; */
`;

export const BrandContent = styled.div`
    width: 545px;
    /* border: 1px solid red; */

    h1 {
        margin-bottom: 24px;
        color: ${theme.grey_900};
        font-family: ${theme.title};
        font-size: 4.8rem;
    }

    button {
        display: flex;
        justify-content: space-between;
        width: 224px;
        height: 48px;
        padding: 0px 20px;
        border: 1px solid ${theme.primaryGreen};
        border-radius: 100px;
        color: ${theme.grey_600};
        font-family: ${theme.subTitle};
        font-size: 1.6rem;
        text-transform: initial;
        span {
            color: ${theme.primaryGreen};
        }

        :hover {
            border: 1px solid ${theme.primaryGreen};
            color: ${theme.primaryGreen};
            background-color: white;
        }
    }
`;

export const ButtonWrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
`;

export const ContentText = styled(motion.span)`
    display: block;
    width: 442px;
    margin-bottom: 168px;
    line-height: 35px;
    color: ${theme.grey_800};
    font-family: ${theme.text};
    font-size: 2.2rem;
`;

export const BrandSlide = styled(motion.div)`
    width: 553px;
    box-shadow: 4px 6px 15px rgba(66, 66, 66, 0.15);
    height: 553px;
`;

export const BrandSwiper = styled(Swiper)`
    height: 100%;
    border-radius: 5px;
    box-shadow: 4px 6px 15px rgba(66, 66, 66, 0.15);
    span {
        opacity: 1;
        background-color: white;
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0px 15px !important;
    }

    .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
    }
`;

export const SlideImage = styled.img`
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    border-radius: 5px;
`;
