import React from 'react';
import styled from '@emotion/styled';
import Select from '@mui/material/Select';
import { styled as mui } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import theme from '../../../commons/theme';
import useCheckEng from '../../../util/useCheckEng';

const SelectBox = styled(Select)`
    width: 218px;
    height: 42px;
    background-color: white;
`;

const CustomInput = mui(InputBase)(() => ({
    '& .MuiInputBase-input': {
        padding: '10px 26px 0px 12px',
        border: '1px solid #ced4da',
        borderRadius: 4,
        color: theme.grey_600,
        fontFamily: theme.text,
        fontSize: '1.8rem',
        '&:hover': {
            borderRadius: 4,
            borderColor: theme.primaryGreen,
        },
        '&:focus': {
            borderColor: theme.primaryGreen,
        },
    },
}));

const MenuItemList = styled(MenuItem)`
    font-size: 1.5rem;
    color: ${theme.grey_600};
    font-family: ${theme.text};
`;

function SelectInput({ name, state, handelChange, list, initial }) {
    const isEng = useCheckEng();

    return (
        <SelectBox name={name} value={state} onChange={handelChange} input={<CustomInput />}>
            {initial && (
                <MenuItemList value={initial} disabled style={{ backgroundColor: state === initial && 'white' }}>
                    {initial}
                </MenuItemList>
            )}
            {list.map(el => (
                <MenuItemList key={el.ko} value={el.ko} style={{ backgroundColor: state === el.ko && theme.secondary }}>
                    {isEng ? el.en : el.ko}
                </MenuItemList>
            ))}
        </SelectBox>
    );
}

export default SelectInput;
