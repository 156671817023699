import React from 'react';
import styled from '@emotion/styled';
import Title from '../Title';
import theme from '../../../commons/theme';
import { memberShipMenuMobile } from '../../../commons/data';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { container, title, content, subContainer } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';

function MemberShip({ setService }) {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();
    return (
        <MemberShipWrapper
            style={{ marginBottom: isEng ? '0px' : '48px' }}
            variants={container}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
        >
            {i18n.language === 'ko' && (
                <>
                    <motion.div variants={title} transition={{ duration: 0.7 }}>
                        <Title title={`멤버십 플랜`} text={``} sub={``} />
                    </motion.div>
                    <MemberShipContent variants={content} transition={{ duration: 0.7 }}>
                        <MemberShipContentTitle variants={content} transition={{ duration: 0.7 }}>
                            <h1>통합 서비스</h1>
                            <span>매니저 케어 라이브 에코</span>
                        </MemberShipContentTitle>
                        <MemberShipContentInfo
                            variants={subContainer}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true }}
                        >
                            {memberShipMenuMobile.map(el => (
                                <InfoMenu key={el.id} variants={content} transition={{ duration: 1 }}>
                                    <img src={el.icon} />
                                    <div>
                                        {el.title.map(text => (
                                            <h5 key={text}>{text}</h5>
                                        ))}
                                        <span>{el.sub}</span>
                                    </div>
                                </InfoMenu>
                            ))}
                        </MemberShipContentInfo>
                        <motion.div variants={content} transition={{ duration: 0.7, delay: 1.5 }}>
                            <Link to="apply" smooth={true} duration={1500}>
                                <Button onClick={() => setService('통합')} variant="contained" sx={{ mt: '40px' }}>
                                    문의하기
                                </Button>
                            </Link>
                        </motion.div>
                    </MemberShipContent>
                </>
            )}
        </MemberShipWrapper>
    );
}

export default MemberShip;

const MemberShipWrapper = styled(motion.div)`
    width: 295px;
    margin-bottom: 48px;
`;

const MemberShipContent = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding: 35px 24px;
    border-radius: 4px;
    border: 2px solid white;
    box-shadow: 0px 2px 10px rgba(66, 66, 66, 0.1);
`;

const MemberShipContentTitle = styled(motion.div)`
    width: 100%;
    margin-bottom: 40px;
    h1 {
        margin-bottom: 6px;
        color: ${theme.primaryGreen};
        font-family: ${theme.title};
        font-size: 2.4rem;
    }

    span {
        color: ${theme.grey_800};
        font-family: ${theme.subTitle};
        font-size: 1.8rem;
    }
`;

const MemberShipContentInfo = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

const InfoMenu = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 50%;
    /* border: 1px solid blue; */

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 1px solid gold; */
    }

    h5 {
        color: ${theme.grey_800};
        font-family: ${theme.subTitle};
        font-size: 1.6rem;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
    }

    span {
        color: ${theme.grey_700};
        font-family: ${theme.text};
        font-size: 1.6rem;
        white-space: nowrap;
        text-align: center;
    }

    img {
        width: 50px;
        height: 50px;
        margin-bottom: 8px;
    }
`;
