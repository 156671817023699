import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import theme from '../../../commons/theme';

export const AppWrapper = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 948px;
    background-color: #fcfcfc;
`;

export const AppContent = styled.div`
    display: flex;
    justify-content: center;
    max-width: 1440px;
    width: 1440px;
    height: 100%;
    padding-top: 240px;
    /* border: 1px solid gold; */
`;

export const AppInfo = styled(motion.div)`
    width: 545px;
    /* border: 1px solid red; */
`;

export const Title = styled.h1`
    color: ${theme.grey_900};
    font-family: ${theme.title};
    font-size: 4.8rem;
    white-space: nowrap;

    span {
        color: ${theme.primaryGreen};
        margin-right: 10px;
    }
`;

export const Text = styled.pre`
    margin-top: ${({ mt }) => mt};
    color: ${({ bold }) => (bold ? theme.primaryGreen : theme.grey_800)};
    font-family: ${({ bold }) => (bold ? theme.subTitle : theme.text)};
    font-size: 2.2rem;
`;

export const GooglePlayButton = styled.button`
    width: 232px;
    height: 62px;
    margin-top: 72px;
    border: 0.5px solid ${theme.grey_400};
    border-radius: 104px;

    :hover {
        cursor: pointer;
    }

    img {
        width: 100%;
        height: 100%;
    }
`;
