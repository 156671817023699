import React, { createContext, useState } from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../util/useCheckEng';

export function ApplyContextProvider({ children }) {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();

    const [service, setService] = useState('통합');
    // const [service, setService] = useState(isEng ? 'test' : '통합');
    const contextValue = {
        service,
        setService,
    };

    return <ApplyContext.Provider value={contextValue}>{children}</ApplyContext.Provider>;
}

export const ApplyContext = createContext(null);
