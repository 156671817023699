import styled from '@emotion/styled';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { container, title, content } from '../../../commons/variants';
import theme from '../../../commons/theme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { slideContent } from '../../../commons/data';
import SlideVideo from '../SlideVideo';
import brochure from '../../../assets/pdf/farmsplan_brochure.pdf';
import brochureEng from '../../../assets/pdf/farmsplan_Eng.pdf';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';

function IntroService() {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <ServiceWrapper>
            <ServiceContent variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                <Title variants={title} transition={{ duration: 0.7 }}>
                    <h1>{t('introService.mtitle')}</h1>
                    <h1>{t('introService.mtitle2')}</h1>
                </Title>
                <motion.div variants={content} transition={{ duration: 0.7 }}>
                    <pre>{t('introService.mtext')}</pre>
                </motion.div>
            </ServiceContent>
            <ServiceSlide variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                <motion.div variants={content} transition={{ duration: 0.7 }}>
                    <Swiper
                        pagination={{ clickable: true }}
                        speed={800}
                        autoplay={{
                            disableOnInteraction: false,
                            delay: 25000,
                        }}
                        modules={[Autoplay, Pagination]}
                        onSlideChange={slide => {
                            slide.activeIndex === 5 ? setActiveIndex(1) : setActiveIndex(slide.activeIndex);
                        }}
                        loop
                    >
                        {slideContent.map(el => (
                            <SwiperSlide key={el.id} data-swiper-autoplay={el.delay}>
                                {({ isActive }) => <SlideVideo isActive={isActive} src={el.video} />}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </motion.div>

                {slideContent.map(
                    (el, idx) =>
                        activeIndex === idx + 1 && (
                            <SlideInfo key={el.id}>
                                <motion.h1
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ duration: 1 }}
                                >
                                    {t(el.title)}
                                </motion.h1>
                                <motion.img
                                    initial={{ opacity: 0, y: 5 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1 }}
                                    src={el.logo}
                                />
                                <motion.pre
                                    style={{ height: isEng ? '250px' : '172px' }}
                                    initial={{ opacity: 0, y: 10 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1 }}
                                    src={el.logo}
                                >
                                    {t(el.m_text)}
                                </motion.pre>
                            </SlideInfo>
                        )
                )}
                <motion.div variants={content} transition={{ duration: 0.7 }}>
                    {i18n.language === 'ko' ? (
                        <Button
                            onClick={() => window.open(brochure, '_blank')}
                            variant="outlined"
                            endIcon={<FileDownloadIcon />}
                        >
                            {t('data.button')}
                        </Button>
                    ) : (
                        <Button
                            onClick={() => window.open(brochureEng, '_blank')}
                            variant="outlined"
                            endIcon={<FileDownloadIcon />}
                        >
                            {t('data.button')}
                        </Button>
                    )}
                </motion.div>
            </ServiceSlide>
        </ServiceWrapper>
    );
}

export default IntroService;

const ServiceWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 155px;
    padding-bottom: 176px;

    pre {
        width: 100%;
        margin-top: 24px;
        color: ${theme.grey_800};
        font-family: ${theme.desc};
        font-size: 1.6rem;
    }

    .swiper {
        width: 375px;
        height: 330px;
        margin-top: 96px;
        /* border: 1px solid red; */
    }

    .swiper-slide {
        height: 296px;
        /* border: 1px solid blue; */
    }

    .swiper-pagination {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 25px;

        span {
            opacity: 1;
            background-color: ${theme.grey_300};
        }
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0px 10px !important;
    }

    .swiper-pagination-bullet-active {
        background-color: ${theme.secondary} !important;
    }

    button {
        display: flex;
        justify-content: space-between;
        width: 295px;
        height: 48px;
        padding: 0px 30px;
        border: 1px solid ${theme.primaryGreen};
        border-radius: 100px;
        color: ${theme.grey_600} !important;
        font-family: ${theme.subTitle};
        font-size: 1.6rem;

        span {
            color: ${theme.primaryGreen} !important;
        }
        :hover {
            border: 1px solid ${theme.primaryGreen};
            color: ${theme.primaryGreen} !important;
            background-color: white;
        }
    }
`;

const ServiceContent = styled(motion.div)`
    width: 295px;
    /* border: 1px solid gold; */
`;

const Title = styled(motion.div)`
    color: ${theme.grey_900};

    h1 {
        line-height: 50px;
        font-family: ${theme.title};
        font-size: 3.8rem;
    }

    span {
        color: ${theme.primaryGreen};
    }
`;

const ServiceSlide = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid gold; */
`;

const SlideInfo = styled.div`
    width: 295px;
    margin-top: 72px;
    /* border: 1px solid red; */

    img {
        width: 190px;
        height: 20px;
        margin-top: 36px;
        margin-bottom: 16px;
        object-fit: contain;
        object-position: left;
    }

    h1 {
        margin-bottom: 12px;
        color: ${theme.primaryGreen};
        font-family: ${theme.title};
        font-size: 3rem;
    }

    pre {
        width: 295px;
        height: 172px;
        margin-top: 0px !important;
        color: ${theme.grey_900};
        font-family: ${theme.text} !important;
        font-size: 1.6rem;
    }
`;
