import React from 'react';
import * as S from './IntroApp.style';
import AppImage from './AppImage';
import { motion } from 'framer-motion';
import LINKS from '../../../commons/links';
import googleIcon from '../../../assets/image/app/google_play.png';
import app1 from '../../../assets/image/app/app_1.png';
import app2 from '../../../assets/image/app/app_2.png';
import app3 from '../../../assets/image/app/app_3.png';
import { container, title, content } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function IntroAppUI() {
    const { i18n } = useTranslation();

    return (
        <S.AppWrapper>
            <S.AppContent>
                <S.AppInfo variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                    <motion.div variants={title} transition={{ duration: 0.7 }}>
                        {i18n.language === 'ko' ? (
                            <>
                                <S.Title>
                                    <span>{t('intro.title')}</span>
                                    {t('intro.title2')}
                                </S.Title>
                                <S.Title>{t('intro.title3')}</S.Title>
                            </>
                        ) : (
                            <>
                                <S.Title>
                                    {t('intro.title2')}
                                    {t('intro.title')}
                                    <span>{t('intro.title3')}</span>
                                </S.Title>
                            </>
                        )}
                    </motion.div>
                    <motion.div variants={content} transition={{ duration: 0.7 }}>
                        <>
                            <S.Text mt="24px">{t('intro.text1')}</S.Text>
                            <S.Text mt="10px">{t('intro.text2')}</S.Text>
                            <S.Text mt="18px" bold={true}>
                                {t('intro.text3')}
                            </S.Text>
                        </>
                    </motion.div>
                    <motion.div variants={content} transition={{ duration: 0.7 }}>
                        <S.GooglePlayButton
                            onClick={() => {
                                window.open(LINKS.farmsplanApp);
                            }}
                        >
                            <img src={googleIcon} alt="icon" />
                        </S.GooglePlayButton>
                    </motion.div>
                </S.AppInfo>
                <AppImage appImage={[app1, app2, app3]} />
            </S.AppContent>
        </S.AppWrapper>
    );
}

export default IntroAppUI;
