import React, { useEffect } from 'react';
import './App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import Navigation from './component/navigation/Navigation.container';
import Laptop from './component/Laptop';
import Mobile from './component/Mobile';
import { useMediaQuery } from '@mui/material';
import './translations/i18n.js';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

function App() {
    const isMobile = useMediaQuery('(max-width:768px)');
    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
    const { i18n } = useTranslation();

    useEffect(() => {
        const isEng = location.pathname.includes('en');
        if (isEng) i18n.changeLanguage('en');
        setLoading(false);
    }, []);

    useEffect(() => {
        document.title = t('header.title');
    });

    if (loading) return null;

    return (
        <>
            <Navigation />
            {isMobile ? <Mobile /> : <Laptop />}
        </>
    );
}

export default App;
