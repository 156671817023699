import React from 'react';
import { useSpring, animated } from 'react-spring';

function NumberAnimation({ number }) {
    const spring = useSpring({
        from: { val: 1 },
        to: { val: number },
        config: {
            duration: 2000,
        },
    });

    return <animated.h1>{spring.val.to(val => Math.floor(val))}</animated.h1>;
}

export default NumberAnimation;
