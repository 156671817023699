import * as React from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import theme from '../../../commons/theme';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useCheckEng from '../../../util/useCheckEng';

export default function SelectInput({ state, setState, item, mb, name, setIsValidation }) {
    const isEng = useCheckEng();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = event => {
        setAnchorEl(null);
        event.target.outerText !== '' && setState(event.target.outerText);

        if (event.target.outerText !== '') {
            setState(event.target.outerText);

            name === '축종'
                ? setIsValidation(pre => ({ ...pre, kind: true }))
                : setIsValidation(pre => ({ ...pre, region: true }));
        }
    };

    const handleButtonText = x => {
        if (isEng && state === '통합') return 'Integrated Service';
        if (isEng && state === '라이브') return 'Live';
        if (isEng && state === '에코') return 'Eco';
        if (isEng && state === '케어') return 'Care';
        if (isEng && state === '매니저') return 'Manager';
        return x;
    };

    return (
        <SelectWrapper mb={mb}>
            <Button onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
                {handleButtonText(state)}
            </Button>
            <MenuList anchorEl={anchorEl} open={open} onClose={handleClose}>
                {item.map(el => (
                    <Item key={el.ko} onClick={handleClose} selected={state === el.ko}>
                        {isEng ? el.en : el.ko}
                    </Item>
                ))}
            </MenuList>
        </SelectWrapper>
    );
}

const SelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: ${({ mb }) => mb && '10px'};

    button {
        display: flex;
        justify-content: space-between;
        width: 295px;
        height: 48px;
        padding: 10px 10px 10px 12px;
        border: 1px solid ${theme.grey_300};
        border-radius: 4px;
        background-color: white;
        color: ${theme.grey_600};
        font-family: ${theme.text};
        font-size: 1.6rem;
        text-transform: initial;
        :hover {
            background-color: white;
            border: 1px solid ${theme.primaryGreen};
        }
    }

    span {
        color: black;
    }
`;

const MenuList = styled(Menu)`
    .MuiPaper-root {
        width: 295px;
        box-shadow: none;
        border: 1px solid ${theme.grey_300};
    }

    .Mui-selected {
        background-color: ${theme.secondary} !important;
    }

    ul {
        box-shadow: none !important;
    }
`;
const Item = styled(MenuItem)`
    font-size: 1.4rem;
    color: ${theme.grey_600};
    font-family: ${theme.text};
`;
