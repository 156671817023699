import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { ApplyContext } from '../../../contexts/ApplyContext';
import { motion } from 'framer-motion';
import { container, title, content } from '../../../commons/variants';
import theme from '../../../commons/theme';
import MemberShip from './MemberShip';
import Personal from './Personal';
import Process from './Process';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';
function IntroPrice() {
    const { i18n } = useTranslation();

    const { setService } = useContext(ApplyContext);
    const isEng = useCheckEng();
    return (
        <PriceWrapper style={{ paddingBottom: isEng ? '0px' : '144px' }}>
            <TitleWrapper variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                <motion.div variants={title} transition={{ duration: 0.7 }}>
                    <h1>{t('introPrice.mtitle')}</h1>
                    {i18n.language === 'ko' && (
                        <>
                            <h1>{t('introPrice.mtitle2')} </h1>
                            <h1>{t('introPrice.mtitle3')}</h1>
                        </>
                    )}
                </motion.div>
                <motion.pre variants={content} transition={{ duration: 0.7 }}>
                    {t('introPrice.mtext')}
                </motion.pre>
            </TitleWrapper>
            <MemberShip setService={setService} />
            <Personal setService={setService} />
            <Process />
        </PriceWrapper>
    );
}

export default IntroPrice;

const PriceWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 144px;

    button {
        width: 247px;
        height: 48px;
        border: none;
        border-radius: 100px;
        box-shadow: none;
        background-color: ${theme.primaryGreen};
        color: white;
        font-family: ${theme.subTitle};
        font-size: 1.6rem;

        :hover {
            box-shadow: none;
            background-color: ${theme.hoverGreen};
            color: white;
        }
    }
`;

const TitleWrapper = styled(motion.div)`
    width: 295px;

    h1 {
        line-height: 50px;
        color: ${theme.grey_900};
        font-family: ${theme.title};
        font-size: 3.8rem;
        white-space: nowrap;
    }

    pre {
        margin-top: 24px;
        margin-bottom: 56px;
        color: ${theme.grey_800};
        font-family: ${theme.desc};
        font-size: 1.6rem;
    }
`;
