import React, { useContext, useEffect, useState } from 'react';
import { ApplyContext } from '../../../contexts/ApplyContext';
import ApplyUI from './Apply.presenter';
import axios from 'axios';
import SuccessModal from '../../modal/SuccessModal';
import ErrorModal from '../../modal/ErrorModal';
import useCheckEng from '../../../util/useCheckEng';

function Apply() {
    const isEng = useCheckEng();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { service, setService } = useContext(ApplyContext);
    const [inputData, setInputData] = useState({
        name: '',
        phone: '',
        kind: isEng ? 'Select breeds' : '축종',
        region: isEng ? 'Select country' : '지역',
    });

    useEffect(() => {
        setInputData({
            name: '',
            phone: '',
            kind: isEng ? 'Select breeds' : '축종',
            region: isEng ? 'Select country' : '지역',
        });
    }, [isEng]);

    const [isValidation, setIsValidation] = useState({
        name: true,
        phone: true,
        kind: true,
        region: true,
    });

    const handleChange = event => {
        setInputData(pre => ({
            ...pre,
            [event.target.name]: event.target.value,
        }));
        setIsValidation(pre => ({
            ...pre,
            [event.target.name]: true,
        }));
    };

    const checkValidation = () => {
        let validation = true;

        if (inputData.name === '') {
            setIsValidation(pre => ({ ...pre, name: false }));
            validation = false;
        }

        if (inputData.phone === '') {
            setIsValidation(pre => ({ ...pre, phone: false }));
            validation = false;
        }

        if (inputData.kind === '축종' || inputData.kind === 'Select breeds') {
            setIsValidation(pre => ({ ...pre, kind: false }));
            validation = false;
        }

        if (inputData.region === '지역' || inputData.region === 'Select country') {
            setIsValidation(pre => ({ ...pre, region: false }));
            validation = false;
        }

        return validation;
    };

    const handleSubmit = async () => {
        if (!checkValidation()) return;

        try {
            setLoading(true);
            const inquiry = {
                name: inputData.name,
                phoneNumber: inputData.phone,
                breed: inputData.kind,
                region: inputData.region,
                service: service,
            };
            const mutation = `
            mutation{
                createInquiry(
                    input: {
                        breed: "${inquiry.breed}", 
                        name: "${inquiry.name}", 
                        phoneNumber: "${inquiry.phoneNumber}", 
                        region: "${inquiry.region}", 
                        service: "${inquiry.service}"
                    }){
                        status
                    }}`;

            const res = await fetch('https://graphql.farmsplan.com/graphql',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({query: mutation}),
                }
            );
            setSuccess(true);
            setInputData({ name: '', phone: '', kind: '축종', region: '지역' });
            setService('통합');
        } catch (error) {
            console.log(error);
            setError(true);
        }
        setLoading(false);
    };

    return (
        <>
            <ApplyUI
                inputData={inputData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isValidation={isValidation}
                service={service}
                setService={setService}
                loading={loading}
            />
            <SuccessModal open={success} handleClose={() => setSuccess(false)} />
            <ErrorModal open={error} handleClose={() => setError(false)} />
        </>
    );
}

export default Apply;
