import styled from '@emotion/styled';
import theme from '../../commons/theme';

export const Banner = styled.img`
    width: 100%;
`;

export const StickyBox = styled.div`
    position: sticky;
    z-index: 999;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
`;

export const Navbar = styled.nav`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
    background-color: white;
    border-bottom: 1px solid ${theme.grey_300};

    @media screen and (max-width: 768px) {
        height: 60px;
    }
`;

// background-color: ${isEng ? 'blue' : 'unset'};

export const NavbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    padding-left: 64px;

    @media screen and (max-width: 768px) {
        padding-left: 20px;
    }
`;

export const Logo = styled.div`
    width: 250px;
    height: 40px;

    :hover {
        cursor: pointer;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media screen and (max-width: 768px) {
        width: 130px;
        height: 22px;
    }
`;

export const MenuWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 40px;

    @media screen and (max-width: 768px) {
        justify-content: flex-end;
    }
`;

export const MenuButton = styled.button`
    width: ${({ width }) => width};
    height: 40px;
    margin: 10px;
    background-color: white;
    border: ${({ outLine }) => (outLine ? '1px solid' + theme.primaryGreen : 'none')};
    border-radius: 4px;
    font-family: ${theme.subTitle};
    font-size: 18px;
    color: ${({ fontColor }) => (fontColor ? theme.primaryGreen : theme.grey_800)};

    :hover {
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        border: none;
        font-size: 14px;
    }
`;


export const MenuPtag = styled.p`
    width: ${({ width }) => width};
    height: 40px;
    line-height: 40px;
    border: ${({ outLine }) => (outLine ? '1px solid' + theme.primaryGreen : 'none')};
    font-family: ${theme.subTitle};
    font-size: 18px;
    color: ${({ fontColor }) => (fontColor ? theme.primaryGreen : theme.grey_800)};
`;