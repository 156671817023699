import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import onehelthBg from '../../../assets/image/onehealth_bg.png';
import theme from '../../../commons/theme';
import i18n from 'i18next';

export const OneHealthWrapper = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 621px;
    margin: 305px 0px;
    background-image: url(${onehelthBg});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Title = styled.h1`
    margin-top: 104px;
    text-align: center;
    color: white;
    font-family: ${theme.title};
    font-size: 4.8rem;

    span {
        margin-left: 15px;
        color: ${theme.primaryGreen};
    }
`;

export const Text = styled.pre`
    width: 890px;
    margin-top: 24px;
    margin-bottom: 64px;
    text-align: center;
    color: white;
    font-family: ${theme.text};
    font-size: 2.2rem;
`;

export const Background = styled(motion.div)`
    position: absolute;
    top: 0;
    width: 100%;
    height: 621px;
    background-color: black;
`;

export const Output = styled(motion.section)`
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    width: 900px;
`;

export const FigureWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    color: white;

    h1 {
        display: inline-block;
        width: 138px;
        margin-right: 10px;
        text-align: right;
        font-family: 'Futura';
        font-size: 10rem;
        font-weight: normal;
    }

    h3 {
        display: inline-block;
        font-family: 'Futura';
        font-size: 4.5rem;
    }
    span {
        display: block;
        margin-left: 10px;
        font-family: ${theme.subTitle};
        font-size: 2.2rem;
    }
    .en_text {
        font-size: 18px;
        font-family: 'Noto-Regular';
        text-align: center;
        white-space: pre-line;
    }
    .en_span {
        text-align: center;
    }
`;

export const Figure = styled.div`
    display: flex;
`;

export const Triangle = styled.div`
    width: 0px;
    height: 0px;
    margin-top: 18px;
    border-bottom: calc(18px * 1.732) solid ${theme.primaryGreen};
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
`;

export const ReverseTriangle = styled.div`
    width: 0px;
    height: 0px;
    margin-top: 18px;
    border-top: calc(18px * 1.732) solid ${theme.primaryGreen};
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
`;
