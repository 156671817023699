import React from 'react';
import styled from '@emotion/styled';
import Title from '../Title';
import theme from '../../../commons/theme';
import { processMenu } from '../../../commons/data';
import { motion } from 'framer-motion';
import { container, title, content, subContainer } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function Process() {
    const { i18n } = useTranslation();
    return (
        <ProcessWrapper variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
            {i18n.language === 'ko' && (
                <>
                    <motion.div variants={title} transition={{ duration: 0.7 }}>
                        <Title title={`도입 절차`} />
                    </motion.div>
                    <ProcessContent>
                        {processMenu.map((el, idx) => (
                            <ProcessStep key={el.id} idx={idx} variants={content} transition={{ duration: 0.7 }}>
                                <img src={el.icon} />
                                {el.title.map(text => (
                                    <h5 key={text}>{text}</h5>
                                ))}
                                <span>{el.sub}</span>
                            </ProcessStep>
                        ))}
                    </ProcessContent>
                </>
            )}
        </ProcessWrapper>
    );
}

export default Process;

const ProcessWrapper = styled(motion.div)`
    width: 295px;
    margin-bottom: 48px;
`;

const ProcessContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 340px;
    margin-top: 34px;
    /* border: 1px solid red; */
`;

const ProcessStep = styled(motion.div)`
    position: absolute;
    top: ${({ idx }) => {
        if (idx === 0) return '0px';
        if (idx === 1) return '50px';
        if (idx === 2) return '190px';
        if (idx === 3) return '235px';
    }};
    right: ${({ idx }) => idx % 2 !== 0 && '0px'};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    color: ${theme.grey_800};
    font-family: ${theme.desc};
    /* border: 1px solid blue; */

    img {
        width: 80px;
        height: 80px;
        margin-bottom: 8px;
        object-fit: cover;
    }

    h5 {
        font-size: 1.6rem;
        white-space: nowrap;
    }

    span {
        color: ${theme.grey_700};
        font-size: 1.2rem;
    }
`;
