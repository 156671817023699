import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { motion, useTransform, useViewportScroll } from 'framer-motion';

const Wrapper = styled.div`
    position: relative;
    width: 545px;
    height: 706px;
    /* border: 1px solid blue; */
`;

const Image = styled.div`
    position: absolute;
    top: ${({ top }) => top};
    right: ${({ right }) => right};
    width: 291px;
    height: 537px;
    background-image: url(${({ src }) => src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
`;

function AppImage({ appImage }) {
    const { scrollYProgress } = useViewportScroll();
    const y = useTransform(scrollYProgress, [0, 1], [0, -1000]);
    return (
        <Wrapper>
            <motion.div
                style={{
                    y,
                }}
            >
                <Image top="50px" right="250px" src={appImage[0]} />
            </motion.div>
            <motion.div
                style={{
                    y,
                }}
            >
                <Image top="300px" right="-30px" src={appImage[1]} />
            </motion.div>
            <motion.div
                style={{
                    y,
                }}
            >
                <Image top="600px" right="250px" src={appImage[2]} />
            </motion.div>
        </Wrapper>
    );
}

export default AppImage;
