import styled from '@emotion/styled';
import theme from '../../../commons/theme';
import { motion } from 'framer-motion';

export const PriceWrapper = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 196px;
`;

export const PriceContent = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    /* border: 1px solid gold; */

    button {
        width: 208px;
        height: 48px;
        border: none;
        border-radius: 100px;
        box-shadow: none;
        background-color: ${theme.primaryGreen};
        color: white;
        font-family: ${theme.subTitle};
        font-size: 1.8rem;

        :hover {
            box-shadow: none;
            background-color: ${theme.hoverGreen};
            color: white;
        }
    }
`;

export const Title = styled.h1`
    color: ${theme.grey_900};
    font-family: ${theme.title};
    font-size: 4.8rem;
`;

export const Text = styled(motion.pre)`
    width: 100%;
    white-space: pre-wrap;
    margin-top: 24px;
    color: ${theme.grey_800};
    font-family: ${theme.text};
    font-size: 2.2rem;
`;
