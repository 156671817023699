import React from 'react';
import Main from './main/laptop/Main.container';
import IntroApp from './introApp/laptop/IntroApp.container';
import IntroService from './introService/laptop/IntroService.container';
import IntroOneHealth from './introOneHealth/laptop/IntroOneHealth.cotainer';
import IntroBrand from './introBrand/laptop/IntroBrand.container';
import IntroPrice from './introPrice/laptop/IntroPrice.container';
import Apply from './apply/laptop/Apply.container';
import Footer from './footer/laptop/Footer.container';

function Laptop() {
    return (
        <>
            <Main />
            <IntroApp />
            <IntroService />
            <IntroOneHealth />
            <IntroBrand />
            <IntroPrice />
            <Apply />
            <Footer />
        </>
    );
}

export default Laptop;
