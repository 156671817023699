import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { MainWrapper } from './Main.style';
import { container, title, content } from '../../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';

function Main() {
    // const { i18n, t } = useTranslation();
    const isEng = useCheckEng();
    // const isEng = i18n.language === 'en';
    return (
        <MainWrapper id="main" isEng={isEng}>
            <motion.div
                variants={container}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true }}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
                <motion.h3 variants={title} transition={{ duration: 0.7 }}>
                    {t('main.title')}
                </motion.h3>
                {/* <Button onClick={() => i18n.changeLanguage('en')}>Change Lang</Button> */}
                <motion.div variants={title} transition={{ duration: 0.7 }}>
                    {/* <h1 style={{ color: isEng ? 'blue' : 'red' }}>{t('main.title2')}</h1> */}
                    <h1>{t('main.title2')}</h1>
                    <h1>{t('main.title3')}</h1>
                </motion.div>
                <motion.pre variants={content} transition={{ duration: 0.7 }}>
                    {t('main.text')}
                </motion.pre>
                <motion.div variants={content} transition={{ duration: 0.7 }}>
                    <Link to="apply" smooth={true} offset={-100} duration={1500}>
                        <Button variant="contained">{t('main.button')}</Button>
                    </Link>
                </motion.div>
            </motion.div>
        </MainWrapper>
    );
}

export default Main;
