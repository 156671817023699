import React from 'react';
import Apply from './apply/mobile/Apply.container';
import Footer from './footer/mobile/Footer.container';
import IntroApp from './introApp/mobile/IntroApp.container';
import IntroBrand from './introBrand/mobile/IntroBrand.container';
import IntroOneHealth from './introOneHealth/mobile/IntroOneHealth.container';
import IntroPrice from './introPrice/mobile/IntroPrice.container';
import IntroService from './introService/mobile/IntroService.container';
import Main from './main/mobile/Main.container';

function Mobile() {
    return (
        <>
            <Main />
            <IntroApp />
            <IntroService />
            <IntroOneHealth />
            <IntroBrand />
            <IntroPrice />
            <Apply />
            <Footer />
        </>
    );
}

export default Mobile;
