const LINKS = {
    gullyjumper: 'https://www.gullyjumper.com',
    farmsplanMarket: 'https://smartstore.naver.com/farmsplan',
    farmsplanApp: 'https://play.google.com/store/apps/details?id=com.farmsplan.app',
    aidkrHomepage: 'https://aidkr.com',
    aidkrBlog: 'https://blog.naver.com/aidkr',
    farmsplanDesign: 'https://designsystem.aidkr.com'
};

export default LINKS;
