import React from 'react';
import styled from '@emotion/styled';
import theme from '../../commons/theme';

const TitleWrapper = styled.div`
    @media all and (max-width: 768px) {
        width: 295px;
    }

    /* border: 1px solid gold; */
    white-space: nowrap;

    pre {
        display: inline-block;
        color: ${theme.primaryGreen};
        font-family: ${theme.desc};
        font-size: 1.6rem;
    }
`;

const SubTitle = styled.h3`
    display: inline-block;
    margin-right: 20px;
    color: ${theme.grey_900};
    font-family: ${theme.title};
    font-size: 3.4rem;

    @media all and (max-width: 768px) {
        margin-right: 10px;
        font-size: 3rem;
    }
`;

const SubText = styled.span`
    color: ${theme.primaryGreen};
    font-family: ${theme.subTitle};
    font-size: 2.2rem;

    @media all and (max-width: 768px) {
        font-size: 1.8rem;
    }
`;

function Title({ title, text, sub }) {
    return (
        <TitleWrapper>
            <SubTitle>{title}</SubTitle>
            <SubText>{text}</SubText>
            <pre>{sub}</pre>
        </TitleWrapper>
    );
}

export default Title;
