import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import styled from '@emotion/styled';
import theme from '../../../commons/theme';

const Slide = styled.div`
    width: 216px;
    margin: auto;
    margin-top: 64px;
    margin-bottom: 64px;
`;

const AppSwiper = styled(Swiper)`
    height: 100%;
    span {
        opacity: 1;
        background-color: ${theme.grey_300};
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0px 10px !important;
    }

    .swiper-pagination-bullet-active {
        background-color: ${theme.primaryGreen} !important;
    }
`;

const SlideImage = styled.img`
    width: 90%;
    height: 90%;
    object-fit: contain;
    margin-left: 17px;
    margin-bottom: 25px;
`;

function AppSlide({ appImage }) {
    return (
        <Slide>
            <AppSwiper
                pagination={{ clickable: true }}
                speed={800}
                autoplay={{ disableOnInteraction: false, delay: 2500 }}
                modules={[Autoplay, Pagination]}
                loop
            >
                <SwiperSlide>
                    <SlideImage src={appImage[0]} />
                </SwiperSlide>
                <SwiperSlide>
                    <SlideImage src={appImage[1]} />
                </SwiperSlide>

                <SwiperSlide>
                    <SlideImage src={appImage[2]} />
                </SwiperSlide>
            </AppSwiper>
        </Slide>
    );
}

export default AppSlide;
