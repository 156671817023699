const color = {
    primaryGreen: '#00CD31',
    hoverGreen: '#00BD25',
    secondary: '#7AE48C',
    warning: '#E6003F',
    grey_300: '#E0E0E0',
    grey_400: '#BDBDBD',
    grey_600: '#757575',
    grey_700: '#616161',
    grey_800: '#424242',
    grey_900: '#222222',
};

const font = {
    mainTitle: 'Noto-Black',
    title: 'Noto-Bold',
    subTitle: 'Noto-Medium',
    desc: 'Noto-Regular',
    text: 'Noto-Light',
};

const theme = {
    ...color,
    ...font,
};

export default theme;
