import React, { useContext, useState, useEffect } from 'react';
import { ApplyContext } from '../../../contexts/ApplyContext';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { container, title, content } from '../../../commons/variants';
import theme from '../../../commons/theme';
import { SERVICE, LIVESTOCK, REGION, SERVICE_ENG, SERVICE_STATE, REGION_ENG } from '../../../commons/data';
import SelectInput from './SelectInput';
import axios from 'axios';
import SuccessModal from '../../modal/SuccessModal';
import ErrorModal from '../../modal/ErrorModal';
import Loading from '../Loading';
import Button from '@mui/material/Button';
import { t } from 'i18next';
import useCheckEng from '../../../util/useCheckEng';

function Apply() {
    const isEng = useCheckEng();
    const { service, setService } = useContext(ApplyContext);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [kind, setKind] = useState(t('apply.label3_option1'));
    const [region, setRegion] = useState(t('apply.label4_option1'));
    const [isValidation, setIsValidation] = useState({
        name: true,
        phone: true,
        kind: true,
        region: true,
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setKind(t('apply.label3_option1'));
        setRegion(t('apply.label4_option1'));
    }, [isEng]);

    const checkValidation = () => {
        let validation = true;

        if (name === '') {
            setIsValidation(pre => ({ ...pre, name: false }));
            validation = false;
        }

        if (phone === '') {
            setIsValidation(pre => ({ ...pre, phone: false }));
            validation = false;
        }

        if (kind === '축종' || kind === 'Select breeds') {
            setIsValidation(pre => ({ ...pre, kind: false }));
            validation = false;
        }

        if (region === '지역' || region === 'Select country') {
            setIsValidation(pre => ({ ...pre, region: false }));
            validation = false;
        }

        return validation;
    };

    const handleSubmit = async () => {
        if (!checkValidation()) return;

        try {
            setLoading(true);
            const result = await axios.post('https://api.farmsplan.com/farmsplan/applications', {
                name,
                phone,
                kind,
                region,
                service,
            });
            setSuccess(true);
            reset();
        } catch (error) {
            console.log(error);
            setError(true);
        }
        setLoading(false);
    };

    const handleChange = event => {
        if (event.target.name === 'name') {
            setName(event.target.value);
            setIsValidation(pre => ({ ...pre, name: true }));
        }

        if (event.target.name === 'phone') {
            setPhone(event.target.value);
            setIsValidation(pre => ({ ...pre, phone: true }));
        }
    };

    const reset = () => {
        setService('');
        setName('');
        setPhone('');
        setKind('축종');
        setRegion('지역');
    };

    return (
        <ApplyWrapper id="apply">
            <Loading loading={loading} />
            <TitleWrapper>
                <h1>{t('apply.mtitle')}</h1>
                <pre>{t('apply.mtext')}</pre>
            </TitleWrapper>
            <ApplyForm>
                <InputWrapper>
                    <label>
                        {t('apply.mlabel1')}
                        <span>*</span>
                    </label>
                    <InputBox
                        value={name}
                        onChange={handleChange}
                        name="name"
                        type="text"
                        placeholder={t('apply.mlabel1_ph')}
                        autoComplete="off"
                    />
                    {!isValidation.name && <ErrorInfo>{t('apply.mlabel1_error')}</ErrorInfo>}
                </InputWrapper>
                <InputWrapper>
                    <label>
                        {t('apply.mlabel2')}
                        <span>*</span>
                    </label>
                    <SelectInput
                        handelChange={event => setService(event.target.value)}
                        state={service}
                        setState={setService}
                        item={SERVICE}
                    />
                </InputWrapper>
                <InputWrapper>
                    <label>
                        {t('apply.mlabel3')}
                        <span>*</span>
                    </label>
                    <SelectInput
                        name="축종"
                        state={kind}
                        setState={setKind}
                        item={LIVESTOCK}
                        mb={true}
                        setIsValidation={setIsValidation}
                    />
                    <SelectInput
                        name="지역"
                        state={region}
                        setState={setRegion}
                        // item={REGION}
                        item={isEng ? REGION_ENG : REGION}
                        setIsValidation={setIsValidation}
                    />
                    {(!isValidation.kind || !isValidation.region) && <ErrorInfo>{t('apply.mlabel3_error')}</ErrorInfo>}
                </InputWrapper>
                <InputWrapper>
                    <label>
                        {t('apply.mlabel4')} <span>*</span>
                    </label>
                    <InputBox
                        value={phone}
                        onChange={handleChange}
                        name="phone"
                        type="number"
                        placeholder={t('apply.mlabel1_ph')}
                    />
                    {!isValidation.phone && <ErrorInfo>{t('apply.mlabel4_error')}</ErrorInfo>}
                </InputWrapper>
            </ApplyForm>
            <SubmitButton variant="contained" onClick={handleSubmit}>
                {t('apply.mbutton')}
            </SubmitButton>
            <SuccessModal open={success} handleClose={() => setSuccess(false)} />
            <ErrorModal open={error} handleClose={() => setError(false)} />
        </ApplyWrapper>
    );
}

export default Apply;

const ApplyWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 88px;
    padding-bottom: 176px;
    background-color: #fcfcfc;
`;

const TitleWrapper = styled.div`
    width: 295px;
    /* border: 1px solid gold; */

    h1 {
        line-height: 50px;
        color: ${theme.grey_900};
        font-family: ${theme.title};
        font-size: 3rem;
        white-space: nowrap;
    }

    pre {
        margin-bottom: 40px;
        color: ${theme.primaryGreen};
        font-family: ${theme.desc};
        font-size: 1.6rem;
    }
`;

const ApplyForm = styled.form`
    width: 295px;
    /* border: 1px solid red; */
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
    /* border: 1px solid blue; */
    font-family: ${theme.subTitle};

    label {
        margin-bottom: 14px;
        color: ${theme.grey_900};
        font-size: 1.8rem;
        white-space: nowrap;
        letter-spacing: -0.5px;

        span {
            color: ${theme.primaryGreen};
        }
    }
`;

const InputBox = styled.input`
    width: 295px;
    height: 48px;
    padding: 10px 26px 10px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: ${theme.grey_600};
    font-family: ${theme.text};
    font-size: 1.6rem;
    appearance: none;

    :hover {
        border: 1px solid ${theme.primaryGreen};
        cursor: pointer;
    }

    :focus {
        outline: none;
        border: 1px solid ${theme.primaryGreen};
    }
`;

const ErrorInfo = styled.span`
    margin-top: 4px;
    color: ${theme.warning} !important;
    font-family: ${theme.desc};
    font-size: 1.2rem;
`;

const SubmitButton = styled(Button)`
    display: block;
    width: 295px;
    height: 48px;
    border: none;
    margin-top: 60px;
    border-radius: 100px;
    box-shadow: none;
    background-color: ${theme.primaryGreen};
    color: white;
    font-size: 1.8rem;
    :hover {
        box-shadow: none;
        background-color: ${theme.hoverGreen};
        color: white;
    }
`;
