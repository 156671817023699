import React, { useState } from 'react';
import styled from '@emotion/styled';
import onehelthBg from '../../../assets/image/m_onehealth_bg.png';
import { motion } from 'framer-motion';
import theme from '../../../commons/theme';
import NumberAnimation from '../NumberAnimation';
import { container, content } from '../../../commons/variants';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../../util/useCheckEng';

function IntroOneHealth() {
    const { i18n } = useTranslation();
    const isEng = useCheckEng();

    const [numbers, setNumbers] = useState([0, 0, 0, 0]);
    return (
        <OneHealthWrapper>
            <OneHealthInnerWrapper variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                <Title variants={content} transition={{ duration: 1, delay: 1 }}>
                    {/* <h1>{t('introOne.mtitle')}</h1>
                    <h1>
                        <span>{t('introOne.mtitle2')}</span>
                    </h1> */}
                    {i18n.language === 'ko' ? (
                        <>
                            <h1>{t('introOne.mtitle')}</h1>
                            <h1>
                                <span>{t('introOne.mtitle2')}</span>
                            </h1>
                        </>
                    ) : (
                        <>
                            <h1>
                                <span>{t('introOne.mtitle2')}</span>
                            </h1>
                            <h1 className="intro_title">{t('introOne.mtitle')}</h1>
                        </>
                    )}
                </Title>
                <motion.pre variants={content} transition={{ duration: 1, delay: 1 }}>
                    {t('introOne.mtext')}
                </motion.pre>
                <Output
                    variants={content}
                    transition={{ duration: 1, delay: 1.5 }}
                    viewport={{ once: true }}
                    onViewportEnter={() => setNumbers([67, 65, 30, 30])}
                >
                    <FigureWrapper>
                        <Figure>
                            <NumberAnimation number={numbers[0]} />
                            <div style={{ paddingTop: '10px', paddingLeft: '8px' }}>
                                <ReverseTriangle />
                                <h3>%</h3>
                            </div>
                        </Figure>
                        <span className={isEng ? 'text_center' : 'text_nowrap'}>{t('introOne.msub_title')}</span>
                    </FigureWrapper>
                    <FigureWrapper>
                        <Figure>
                            <NumberAnimation number={numbers[1]} />
                            <div style={{ paddingTop: '10px', paddingLeft: '8px' }}>
                                <ReverseTriangle />
                                <h3>%</h3>
                            </div>
                        </Figure>
                        <span className={isEng ? 'text_center' : 'text_nowrap'}>{t('introOne.msub_title2')}</span>
                    </FigureWrapper>
                    <FigureWrapper>
                        <Figure>
                            <NumberAnimation number={numbers[2]} />
                            <div style={{ paddingTop: '10px', paddingLeft: '8px' }}>
                                <Triangle />
                                <h3>%</h3>
                            </div>
                        </Figure>
                        <span className={isEng ? 'text_center' : 'text_nowrap'}>{t('introOne.msub_title3')}</span>
                    </FigureWrapper>
                    <FigureWrapper>
                        <Figure>
                            <NumberAnimation number={numbers[3]} />
                            <div style={{ paddingTop: '10px', paddingLeft: '8px' }}>
                                <Triangle />
                                <h3>%</h3>
                            </div>
                        </Figure>
                        <span className={isEng ? 'text_center' : 'text_nowrap'}>{t('introOne.msub_title4')}</span>
                        {i18n.language === 'en' && <span className="en_text">{t('introOne.sub_title4_1')}</span>}
                    </FigureWrapper>
                </Output>
            </OneHealthInnerWrapper>

            <Background
                initial={{ opacity: 0, x: '-100%' }}
                whileInView={{ opacity: 0.3, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
            />
        </OneHealthWrapper>
    );
}

export default IntroOneHealth;

const OneHealthWrapper = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: url(${onehelthBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
`;

const OneHealthInnerWrapper = styled(motion.div)`
    z-index: 1;
    width: 295px;
    height: 100%;
    padding-top: 96px;
    padding-bottom: 132px;
    color: white;

    pre {
        margin-top: 24px;
        font-family: ${theme.desc};
        font-size: 1.6rem;
        line-height: 26px;
    }
`;

const Title = styled(motion.div)`
    h1 {
        font-family: ${theme.title};
        font-size: 3.8rem;
        line-height: 50px;
        white-space: nowrap !important;

        span {
            color: ${theme.primaryGreen};
        }
    }
    @media all and (max-width: 768px) {
        .intro_title {
            white-space: pre-line !important;
        }
    }
`;

const Background = styled(motion.div)`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
`;

const Output = styled(motion.section)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 295px;
    margin-top: 80px;
`;

const FigureWrapper = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 123px;
    margin-bottom: 32px;
    color: white;

    span {
        display: block;
        font-family: ${theme.subTitle};
        font-size: 1.8rem;
        white-space: nowrap;
    }
`;

const Figure = styled.div`
    display: flex;

    h1 {
        width: 81px;
        display: inline-block;
        font-family: 'Futura';
        font-size: 6rem;
        font-weight: normal;
    }

    h3 {
        display: inline-block;
        font-family: 'Futura';
        font-size: 2.6rem;
    }
`;

const Triangle = styled.div`
    width: 0px;
    height: 0px;
    margin-bottom: 5px;
    border-bottom: calc(10px * 1.732) solid ${theme.primaryGreen};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
`;

const ReverseTriangle = styled.div`
    width: 0px;
    height: 0px;
    margin-bottom: 5px;
    border-top: calc(10px * 1.732) solid ${theme.primaryGreen};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
`;
