import liveLog from '../assets/image/service/en_logo_live.png';
import liveBg from '../assets/image/service/bg_live.png';
import ecoLog from '../assets/image/service/en_logo_eco.png';
import ecoBg from '../assets/image/service/bg_eco.png';
import careLog from '../assets/image/service/en_logo_care.png';
import careBg from '../assets/image/service/bg_care.png';
import managerLog from '../assets/image/service/en_logo_manager.png';
import managerBg from '../assets/image/service/bg_manager.png';
import bell from '../assets/image/price/bell_icon.png';
import report from '../assets/image/price/report_icon.png';
import camera from '../assets/image/price/camera_icon.png';
import care from '../assets/image/price/care_icon.png';
import chart from '../assets/image/price/chart_icon.png';
import sensor from '../assets/image/price/sensor_icon.png';
import chatIcon from '../assets/image/process/chat_icon.png';
import contractIcon from '../assets/image/process/contract_icon.png';
import homeIcon from '../assets/image/process/home_icon.png';
import settingIcon from '../assets/image/process/setting_icon.png';
import liveVideo from '../assets/video/live.mp4';
import ecoVideo from '../assets/video/eco.mp4';
import careVideo from '../assets/video/care.mp4';
import managerVideo from '../assets/video/manager.mp4';
import { t } from 'i18next';

export const slideContent = [
    {
        id: 1,
        logo: liveLog,
        title: 'data.slideTitle1',
        text: 'data.slideText1',
        m_text: `data.slidemText1`,
        image: liveBg,
        video: liveVideo,
        delay: 25000,
    },
    {
        id: 2,
        logo: ecoLog,
        title: 'data.slideTitle2',
        text: 'data.slideText2',
        m_text: `data.slidemText2`,
        image: ecoBg,
        video: ecoVideo,
        delay: 15800,
    },
    {
        id: 3,
        logo: careLog,
        title: 'data.slideTitle3',
        text: 'data.slideText3',
        m_text: `data.slidemText3`,
        image: careBg,
        video: careVideo,
        delay: 12000,
    },
    {
        id: 4,
        logo: managerLog,
        title: 'data.slideTitle4',
        text: 'data.slideText4',
        m_text: `data.slidemText4`,
        image: managerBg,
        video: managerVideo,
        delay: 16500,
    },
];

export const memberShipMenu = [
    {
        id: 1,
        icon: bell,
        title: ['사육환경', '알림 서비스'],
    },
    {
        id: 2,
        icon: sensor,
        title: ['모듈형 환경센서'],
        sub: '',
    },
    {
        id: 3,
        icon: camera,
        title: ['인공지능 카메라'],
        sub: '',
    },
    {
        id: 4,
        icon: report,
        title: ['통합 데이터분석', '리포트'],
    },
    {
        id: 5,
        icon: care,
        title: ['수의사 컨설팅'],
    },
    {
        id: 6,
        icon: chart,
        title: ['가축 활동성/', '행동패턴 분석'],
    },
];

export const memberShipMenuMobile = [
    {
        id: 1,
        icon: bell,
        title: ['사육환경', '알림 서비스'],
    },
    {
        id: 2,
        icon: camera,
        title: ['인공지능 카메라'],
        sub: '자재비/공사비 무료',
    },
    {
        id: 3,
        icon: chart,
        title: ['가축 활동성/', '행동패턴 분석'],
    },
    {
        id: 4,
        icon: report,
        title: ['통합 데이터분석', '리포트'],
    },
    {
        id: 5,
        icon: sensor,
        title: ['모듈형 환경센서'],
        sub: '자재비/공사비 무료',
    },
    {
        id: 6,
        icon: care,
        title: ['수의사 컨설팅'],
    },
];

export const processMenu = [
    {
        id: 1,
        icon: chatIcon,
        title: ['농장 맞춤형 팜스플랜', '서비스 신청하기'],
    },
    {
        id: 2,
        icon: contractIcon,
        title: ['팜스플랜 관리자가', '방문하여 서비스 계약'],
    },
    {
        id: 3,
        icon: settingIcon,
        title: ['서비스 도입을 위한', '설치 공사 진행'],
        sub: '(라이브/에코 서비스만)',
    },
    {
        id: 4,
        icon: homeIcon,
        title: ['팜스플랜 서비스', '마음껏 누리기!'],
    },
];

export const personalMenu = [
    {
        id: 1,
        title: 'personal.listTitle1',
        service: '라이브',
        sub: 'personal.listSub1',
        text: [
            `personal.listText1_1`,
            `personal.listText1_2`,
            `personal.listText1_3`,
        ],
    },
    {
        id: 2,
        title: 'personal.listTitle2',
        service: '에코',
        sub: 'personal.listSub2',
        dec: 'personal.sub_dec',
        text: [
            `personal.listText2_1`,
            `personal.listText2_2`,
            `personal.listText2_3`,
        ],
    },
    {
        id: 3,
        title: 'personal.listTitle3',
        service: '케어',
        sub: 'personal.listSub3',
        text: [
            `personal.listText3_1`,
            `personal.listText3_2`,
            `personal.listText3_3`,
            `personal.listText3_4`,
            `personal.listText3_5`,
        ],
    },
    {
        id: 4,
        title: 'personal.listTitle4',
        service: '매니저',
        sub: 'personal.listSub4',
        dec: 'personal.sub_dec',
        text: [
            `personal.listText4_1`,
            `personal.listText4_2`,
            `personal.listText4_3`,
            `personal.listText4_4`,
            `personal.listText4_5`,
        ],
    },
];

export const SERVICE = [
    {
        ko: '통합',
        en: 'Integrated Service',
    },
    {
        ko: '라이브',
        en: 'Live',
    },
    {
        ko: '에코',
        en: 'Eco',
    },
    {
        ko: '케어',
        en: 'Care',
    },
    {
        ko: '매니저',
        en: 'Manager',
    },
];

export const LIVESTOCK = [
    {
        ko: '돼지(모돈)',
        en: 'Pig(Childbirth Sow)',
    },
    {
        ko: '돼지(비육)',
        en: 'Pig(fed pigs)',
    },
    {
        ko: '돼지(일관)',
        en: 'Pig(Consistency)',
    },
    {
        ko: '소(한우)',
        en: 'Cow(Korean native cattle)',
    },
    {
        ko: '소(홀스타인)',
        en: 'Cow(Holstein)',
    },
    {
        ko: '젖소',
        en: 'Milk cow',
    },
    {
        ko: '육계',
        en: 'Broiler chicken',
    },
    {
        ko: '산란계',
        en: 'Layer chicken',
    },
    {
        ko: '토종계',
        en: 'Korean native chicken',
    },
];

export const REGION = [
    {
        ko: '강원도',
    },
    {
        ko: '경기도',
    },
    {
        ko: '경상남도',
    },
    {
        ko: '경상북도',
    },
    {
        ko: '전라남도',
    },
    {
        ko: '전라북도',
    },
    {
        ko: '충청남도',
    },
    {
        ko: '충청북도',
    },
    {
        ko: '제주특별자치도',
    },
];

export const REGION_ENG = [
    {
        ko: '인도',
        en: 'India',
    },
    {
        ko: '말레이시아',
        en: 'Malaysia',
    },
    {
        ko: '영국',
        en: 'UK',
    },
    {
        ko: '미국',
        en: 'USA',
    },
];

// export const SERVICE = ['통합', '라이브', '에코', '케어', '매니저'];
