import React from 'react';
import * as S from './Apply.style';
import Button from '@mui/material/Button';
import { SERVICE, LIVESTOCK, REGION, SERVICE_ENG, SERVICE_STATE, REGION_ENG } from '../../../commons/data';
import Loading from '../Loading';
import SelectInput from './SelectInput';
import { t } from 'i18next';
import useCheckEng from '../../../util/useCheckEng';

function ApplyUI({ inputData, handleChange, handleSubmit, isValidation, service, setService, loading }) {
    const isEng = useCheckEng();

    return (
        <S.ApplyWrapper id="apply">
            <S.ApplyContent>
                <Loading loading={loading} />
                <S.ApplyTitle>
                    <h1>{t('apply.title')}</h1>
                    <span>{t('apply.text')}</span>
                    <Button variant="contained" onClick={handleSubmit}>
                        {t('apply.button')}
                    </Button>
                </S.ApplyTitle>
                <S.ApplyForm>
                    <S.InputWrapper>
                        <label>
                            {t('apply.label1')} <span>*</span>
                        </label>
                        <S.InputBox
                            name="name"
                            type="text"
                            value={inputData.name}
                            onChange={handleChange}
                            placeholder={t('apply.label1_ph')}
                            width="218px"
                            autoComplete="off"
                        />
                        {!isValidation.name && <S.ErrorInfo left="17%">{t('apply.label1_ph')}</S.ErrorInfo>}
                        <label>
                            {t('apply.label2')} <span>*</span>
                        </label>
                        <SelectInput
                            name={'service'}
                            state={service}
                            handelChange={event => setService(event.target.value)}
                            list={SERVICE}
                        />
                    </S.InputWrapper>
                    <S.InputWrapper>
                        <label>
                            {t('apply.label3')} <span>*</span>
                        </label>
                        <SelectInput
                            name={'kind'}
                            state={inputData.kind}
                            handelChange={handleChange}
                            list={LIVESTOCK}
                            initial={t('apply.label3_option1')}
                        />
                        {!isValidation.kind && <S.ErrorInfo left="17%">{t('apply.label3_error')}</S.ErrorInfo>}
                        <label htmlFor="region">
                            {t('apply.label4')} <span>*</span>
                        </label>
                        <SelectInput
                            name={'region'}
                            state={inputData.region}
                            handelChange={handleChange}
                            list={isEng ? REGION_ENG : REGION}
                            initial={t('apply.label4_option1')}
                        />
                        {!isValidation.region && <S.ErrorInfo left="69%">{t('apply.label4_error')}</S.ErrorInfo>}
                    </S.InputWrapper>
                    <S.InputWrapper>
                        <label htmlFor="phone">
                            {t('apply.label5')} <span>*</span>
                        </label>
                        <S.InputBox
                            name="phone"
                            type="number"
                            value={inputData.phone}
                            onChange={handleChange}
                            placeholder={t('apply.label5_ph')}
                            width="583px"
                        />
                        {!isValidation.phone && <S.ErrorInfo left="17%">{t('apply.label5_error')}</S.ErrorInfo>}
                    </S.InputWrapper>
                </S.ApplyForm>
            </S.ApplyContent>
        </S.ApplyWrapper>
    );
}

export default ApplyUI;
