import React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import logoImage from '../../assets/image/farmsplan_logo.png';
import krImage from '../../assets/image/kr_btn.png';
import engImage from '../../assets/image/eng_btn.png';
import * as S from './Navigation.style';
import { Link } from 'react-scroll';
import { useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import kakaoBanner from '../../assets/image/banner_top.png';

function HideOnScroll({ children, window }) {
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function Navigation(props) {
    const breakPoint = useMediaQuery('(max-width:768px)');
    const { i18n, t } = useTranslation();
    const isEng = useCheckEng();
    const isMobile = useMediaQuery('(max-width:768px)');
    const kakaoLink = "https://forms.office.com/r/aR9EGxEzLL"

    return (
        <HideOnScroll {...props}>
            <S.StickyBox>
                {!isMobile && 
                    <a target="_blank" href={kakaoLink} rel="noreferrer">
                        <S.Banner src={kakaoBanner} />
                    </a>
                }
                <S.Navbar>
                    <S.NavbarWrapper>
                        <Link to="main" smooth={true} offset={-100} duration={1500}>
                            <S.Logo>
                                <img src={logoImage} alt="logo" />
                            </S.Logo>
                        </Link>
                        <S.MenuWrapper>
                            {!breakPoint && (
                                <S.MenuPtag
                                    isEng={isEng}
                                    width="143px"
                                >
                                    {t('header.service')}
                                </S.MenuPtag>
                            )}
                            <Link to="apply" smooth={true} duration={1500}>
                                <S.MenuButton width="100px" outLine={true} fontColor={true}>
                                    {t('header.free')}
                                </S.MenuButton>
                            </Link>
                            {i18n.language === 'ko' ? (
                                <Button onClick={() => i18n.changeLanguage('en')}>
                                    <img src={engImage} alt="logo" />
                                </Button>
                            ) : (
                                <Button onClick={() => i18n.changeLanguage('ko')}>
                                    <img src={krImage} alt="logo" />
                                </Button>
                            )}
                        </S.MenuWrapper>
                    </S.NavbarWrapper>
                </S.Navbar>
            </S.StickyBox>
        </HideOnScroll>
    );
}

export default Navigation;
