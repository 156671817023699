import React from 'react';
import * as S from './IntroBrand.style';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { motion } from 'framer-motion';
import farmsplan from '../../../assets/image/brand/farmsplan.png';
import meat from '../../../assets/image/brand/meat.png';
import gully from '../../../assets/image/brand/gully.jpg';
import artlist from '../../../assets/image/brand/artlist.jpg';
import LINKS from '../../../commons/links';
import { container, title, content } from '../../../commons/variants';
import { t } from 'i18next';
import useCheckEng from '../../../util/useCheckEng';

const SLIDEIMAGE = [farmsplan, meat, gully, artlist];

function IntroBrand() {
    const isEng = useCheckEng();
    return (
        <S.BrandWrapper>
            <S.BrandContent>
                <motion.div variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                    <motion.h1 variants={title} transition={{ duration: 0.7 }}>
                        {t('introBrand.title')}
                    </motion.h1>
                    <S.ContentText
                        variants={content}
                        transition={{ duration: 0.7 }}
                        style={{ marginBottom: isEng ? '92px' : '168px' }}
                    >
                        {t('introBrand.text')}
                    </S.ContentText>
                    <S.ButtonWrapper variants={content} transition={{ duration: 0.7 }}>
                        <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIcon />}
                            onClick={() => {
                                window.open(LINKS.gullyjumper);
                            }}
                        >
                            {t('introBrand.link1')}
                        </Button>
                        <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIcon />}
                            onClick={() => {
                                window.open(LINKS.farmsplanMarket);
                            }}
                        >
                            {t('introBrand.link2')}
                        </Button>
                    </S.ButtonWrapper>
                </motion.div>
            </S.BrandContent>
            <S.BrandSlide
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: 0.3 }}
                viewport={{ once: true }}
            >
                <S.BrandSwiper
                    pagination={{ clickable: true }}
                    speed={800}
                    autoplay={{ disableOnInteraction: false, delay: 2500 }}
                    modules={[Autoplay, Pagination]}
                    loop
                >
                    {SLIDEIMAGE.map(el => (
                        <SwiperSlide key={el}>
                            <S.SlideImage src={el} />
                        </SwiperSlide>
                    ))}
                </S.BrandSwiper>
            </S.BrandSlide>
        </S.BrandWrapper>
    );
}

export default IntroBrand;
